import * as React from 'react';
import { Box, IconButton, Grid, Button, CardMedia } from '@mui/material';
import { COLORS, BREAKPOINTS } from '../../../utils/theme';
import { HOME_ROUTE } from '../../../utils/Route';
import { navigate } from 'gatsby-link';
import globalMessages from '../../../utils/messages/en/globalMessages';
import logoDoc from '../../../../static/images/logoDoc.svg';
import logoApi from '../../../../static/images/LogoAPI.svg';
import CollapseIcon from '../../../../static/images/leftNavCollapse.svg';
import ExpandIcon from '../../../../static/images/leftNavExpand.svg';
import NavMenu from '../NavMenu';
import Drawer from '@mui/material/Drawer';
import { isMobileView } from '../../../utils/helper';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';

const useStyles = {
  gridStyling: {
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: COLORS.BACKGROUND_PRIMARY,
  },
  innerBox: {
    padding: '18px',
    height: '24px',
    width: '234px',
    backgroundColor: COLORS.BACKGROUND_PRIMARY,
    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'row-reverse',
      direction: 'rtl',
      padding: '0px',
      width: '124px',
      height: '18px',
    },
  },
  drawerStyle: {
    padding: '24px 16px 20px 20px',
    width: '240px',
    height: '18px',
    backgroundColor: COLORS.BACKGROUND_PRIMARY,
    [BREAKPOINTS.MOBILE]: {
      width: '124px',
      direction: 'rtl',
    },
  },
  logoStyling: {
    display: 'flex',
  },
  buttonStyle: {
    padding: '0px',
    '&:hover': {
      background: 'transparent',
    },
  },
  LogoSubLogoStyle: {
    minWidth: '138px',
    height: '24px',
    display: 'flex',
    justifyContent: 'flex-start',
    [BREAKPOINTS.MOBILE]: {
      minWidth: '88px',
      height: '15px',
    },
  },
  ApiLogoStyle: {
    left: '0px',
    [BREAKPOINTS.MOBILE]: {
      minWidth: '96px',
      height: '15px',
    },
  },
};
function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
export interface HeaderLogoProps {
  type: 'docs' | 'api' | 'apireference';
  open: boolean;
  setOpen: (boolean) => void;
  setType: (string) => void;
  sideNavItems: Array<Record<string, unknown>>;
  level: number;
  pathName: string;
  activeItem: string;
  setActiveItem: (any) => void;
  parentActiveItem: string;
  setParentActiveItem: (any) => void;
}

const checkIfApiPage = (type) => {
  if (type && type.toUpperCase() === globalMessages.apiReferencePage) {
    return (
      <Box
        component="img"
        src={logoApi}
        alt="API"
        sx={{ ...useStyles.ApiLogoStyle }}
      />
    );
  } else {
    return (
      <Box
        component="img"
        src={logoDoc}
        alt="DOCS"
        sx={useStyles.LogoSubLogoStyle}
      />
    );
  }
};
const HeaderLogo: React.FC<HeaderLogoProps> = (props) => {
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    props.setOpen(open);
  };
  const list = (anchor, handleLogoClick) => 
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 305 }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box sx={useStyles.drawerStyle}>
        <Grid container sx={useStyles.gridStyling}>
          <Grid item sx={useStyles.logoStyling}>
            <Button
              onClick={() => handleLogoClick(props.setType)}
              sx={useStyles.buttonStyle}
              disableRipple
            >
              <Box sx={useStyles.LogoSubLogoStyle}>
                {checkIfApiPage(props.type)}
              </Box>
            </Button>
          </Grid>
          <Grid item>
            <IconButton
              sx={useStyles.buttonStyle}
              onClick={toggleDrawer('left', false)}
              disableRipple
            >
              <img src={props.open ? CollapseIcon : ExpandIcon} alt="expand" />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <NavMenu
        sideNavItems={props.sideNavItems}
        level={props.level}
        type={props.type}
        pathName={props.pathName}
        setOpen={props.setOpen}
        activeItem={props.activeItem}
        setActiveItem={props.setActiveItem}
        parentActiveItem={props.parentActiveItem}
        setParentActiveItem={props.setParentActiveItem}
      />
    </Box>
  ;
  const handleLogoClick = (setType) => {
    navigate(HOME_ROUTE);
    setType('docs');
    if(isMobileView()){
      props.setOpen(false);
    }
  };
  const handleClick = () => {
    props.setOpen(!props.open);
  };
  const customHeaderLogo = () => {
    return (
      <Box sx={useStyles.innerBox}>
        <Grid container sx={useStyles.gridStyling}>
          <Grid item sx={useStyles.logoStyling}>
            <Button
              onClick={() => handleLogoClick(props.setType)}
              sx={useStyles.buttonStyle}
              disableRipple
            >
              <Box sx={useStyles.LogoSubLogoStyle}>
                {checkIfApiPage(props.type)}
              </Box>
            </Button>
          </Grid>
          <Grid item>
            <IconButton
              sx={useStyles.buttonStyle}
              onClick={
                isMobileView()
                  ? toggleDrawer('left', true)
                  : () => handleClick()
              }
              disableRipple
            >
              <img src={props.open ? CollapseIcon : ExpandIcon} alt="expand" />
            </IconButton>
            {isMobileView() && 
              <Drawer
                variant="temporary"
                anchor={'left'}
                open={props.open}
                onClose={toggleDrawer('left', false)}
              >
                {list('left', handleLogoClick)}
              </Drawer>
            }
          </Grid>
        </Grid>
      </Box>
    );
  };
  return (
    <Box>
      {props.open && customHeaderLogo()}
      {!props.open && !isMobileView() && 
        <HideOnScroll>{customHeaderLogo()}</HideOnScroll>
      }
      {!props.open && isMobileView() && customHeaderLogo()}
    </Box>
  );
};
export default React.memo(HeaderLogo);
