import React from 'react';
import NavBar from '../NavBar/index';
import { Box, IconButton } from '@mui/material';
import Typography from '../Typography';
import ArrowForwardIcon from '../../../../static/images/arrow-right.svg';
import theme, { COLORS, BREAKPOINTS } from '../../../utils/theme';
import messages from '../../../utils/messages';
import { handleExternalLinks, isMobileView } from '../../../utils/helper';
import SecurityWrapper from '../SecurityWrapper';

const useStyles = {
  outerBox: {
    width: '270px',
    maxHeight: 'calc(100vh - 64px)',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    [BREAKPOINTS.MOBILE]: {
      height: '90vh',
      overflow: 'unset',
      justifyContent: 'space-between',
    },
  },

  signInbutton: {
    backgroundColor: COLORS.BACKGROUND_TERTIARY,
    borderRadius: '100px',
    paddingLeft: '20px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: '0px 21px',
    alignItems: 'center',
    minHeight: '37px',
    maxHeight: '37px',
    width: '106px',
    ':hover': {
      backgroundColor: COLORS.BACKGROUND_TERTIARY,
    },
    marginBottom: '29px',
    marginLeft: '21px',
    [BREAKPOINTS.DESKTOP]: {
      display: 'none',
    },
  },
  icon: {
    color: theme.palette.secondary.main,
    height: theme.spacing(4),
    width: theme.spacing(4),
    paddingLeft: '6px',
    margin: 'auto',
  },
  signInStyle: {
    color: COLORS.TEXT_TERTIARY,
    '&:hover': {
      textDecorationLine: 'underline',
      textDecorationColor: theme.palette.primary.main,
    },
  },
};

export interface NavMenuProps {
  sideNavItems: Array<Record<string, unknown>>;
  level: number;
  type?: 'docs' | 'api' | 'apireference';
  pathName: string;
  onSignIn?: () => Record<string, unknown>;
  setOpen: (boolean) => void;
  activeItem: string;
  setActiveItem: (any) => void;
  parentActiveItem: string;
  setParentActiveItem: (any) => void;
}

const NavMenu: React.FC<NavMenuProps> = ({
  sideNavItems,
  level,
  type,
  pathName,
  onSignIn,
  setOpen,
  activeItem,
  setActiveItem,
  parentActiveItem,
  setParentActiveItem,
}) => {
  const msg = messages['en'];
  const signIn = () => {
    if (onSignIn) {
      onSignIn();
    }
    const href = 'https://try.skyflow.com';
    handleExternalLinks(href);
  };
  return (
    <Box sx={useStyles.outerBox}>
      <Box>
        <NavBar
          sideNavItems={sideNavItems}
          level={level}
          type={type}
          pathName={pathName}
          setDrawer={setOpen}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          parentActiveItem={parentActiveItem}
          setParentActiveItem={setParentActiveItem}
        />
      </Box>
      {isMobileView() && 
        <Box>
          <SecurityWrapper isHeader={true} isMobileOpenStudio={true}>
            <IconButton
              sx={useStyles.signInbutton}
              onClick={() => signIn()}
              data-testid="signIn"
              disableRipple
            >
              <Typography
                type="DESKTOP_ACTION_SECONDARY_MEDIUM"
                sx={useStyles.signInStyle}
              >
                {msg.signIn}
              </Typography>
              <img
                src={ArrowForwardIcon}
                style={useStyles.icon}
                alt="arrow_right"
              />
            </IconButton>
          </SecurityWrapper>
        </Box>
      }
    </Box>
  );
};

export default React.memo(NavMenu);
