import { isMobile } from 'react-device-detect';
import { FONT_LINE_HEIGHT, FONT_SIZES } from './theme';

export const isMobileView = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < 1024;
  } else {
    return isMobile;
  }
};

export const calculateCodeBlockLineHeight = () => {
  if (typeof window !== 'undefined' && window.innerWidth < 1024) {
    return FONT_LINE_HEIGHT.CODE_BLOCK * FONT_SIZES.CODE_SECONDARY_MOBILE;
  } else {
    return FONT_LINE_HEIGHT.CODE_BLOCK * FONT_SIZES.CODE_SECONDARY;
  }
};

export const handleExternalLinks = (href) => {
  const externalWindow = window.open(href, '');
  let path = '_blank';
  if (typeof window !== 'undefined') {
    path = window.location.href;
  }
  setTimeout(() => {
    const message = { referrer_type: 'SKYFLOW_DOCS', referrer_path: path };
    const domain = new URL(href).host;
    if (
      externalWindow &&
      domain &&
      !domain.startsWith('www') &&
      /^.*\.skyflow.*$/.test(domain)
    ) {
      externalWindow.postMessage(message, href);
    }
  }, 2000);
};
export const appendRouteToUrl = (routeToBeAppended) => {
  let selectionItem = '';
  selectionItem = routeToBeAppended.includes('#')
    ? routeToBeAppended
    : '#' + routeToBeAppended;
  if (selectionItem && selectionItem.includes('#')) {
    const search = '#';
    const replaceWith = '';
    const result = selectionItem.split(search).join(replaceWith);
    const ele = document.getElementById(result);
    if (ele) {
      if (/iPhone/.test(navigator.userAgent)) {
        ele.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      } else {
        ele.scrollIntoView();
      }
      window.history.pushState(
        null,
        '',
        window.location.href.split('#')[0] + selectionItem,
      );
    }
  }
};
