import * as React from 'react';
import { MDXProvider } from '@mdx-js/react';
import Tag from '../Tag';
import Tabs from '../Tabs';
import APIRequestSample from '../APIRequestSample';
import Notice from '../Notice';
import Image from '../../common/Image';
import MDXComponents from '../MDXComponents';
import Iframe from 'react-iframe';
import RequestResponse from '../../api-ref/RequestResponse';
import TwoColumnLayout from '../TwoColumnLayout';
import SyncTabs from '../SyncTabs';

interface Props {
  children?: JSX.Element;
}
const shortcodes = {
  Iframe,
  code: RequestResponse,
  ...MDXComponents,
  img: Image,
  Tag,
  Tabs,
  APIRequestSample,
  Notice,
  TwoColumnLayout,
  SyncTabs,
};
const MDXWrapper: React.FC<Props> = ({ children }) => (
  <MDXProvider components={shortcodes}>{children}</MDXProvider>
);
export default MDXWrapper;
