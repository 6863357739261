exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apireference-tsx": () => import("./../../../src/pages/apireference.tsx" /* webpackChunkName: "component---src-pages-apireference-tsx" */),
  "component---src-pages-content-docs-auth-prod-accounts-mdx": () => import("./../../../src/pages/content/docs/auth-prod-accounts.mdx" /* webpackChunkName: "component---src-pages-content-docs-auth-prod-accounts-mdx" */),
  "component---src-pages-content-docs-auth-trial-accounts-mdx": () => import("./../../../src/pages/content/docs/auth-trial-accounts.mdx" /* webpackChunkName: "component---src-pages-content-docs-auth-trial-accounts-mdx" */),
  "component---src-pages-content-docs-encrypted-analytics-mdx": () => import("./../../../src/pages/content/docs/encrypted-analytics.mdx" /* webpackChunkName: "component---src-pages-content-docs-encrypted-analytics-mdx" */),
  "component---src-pages-content-docs-high-availability-infrastructure-mdx": () => import("./../../../src/pages/content/docs/high-availability-infrastructure.mdx" /* webpackChunkName: "component---src-pages-content-docs-high-availability-infrastructure-mdx" */),
  "component---src-pages-content-docs-how-to-author-a-policy-and-control-access-to-your-vault-mdx": () => import("./../../../src/pages/content/docs/how-to:-author-a-policy-and-control-access-to-your-vault.mdx" /* webpackChunkName: "component---src-pages-content-docs-how-to-author-a-policy-and-control-access-to-your-vault-mdx" */),
  "component---src-pages-content-docs-how-to-generate-an-access-token-for-a-service-account-mdx": () => import("./../../../src/pages/content/docs/how-to:-generate-an-access-token-for-a-service-account.mdx" /* webpackChunkName: "component---src-pages-content-docs-how-to-generate-an-access-token-for-a-service-account-mdx" */),
  "component---src-pages-content-docs-overview-mdx": () => import("./../../../src/pages/content/docs/overview.mdx" /* webpackChunkName: "component---src-pages-content-docs-overview-mdx" */),
  "component---src-pages-content-docs-payment-understanding-the-vault-mdx": () => import("./../../../src/pages/content/docs/payment-understanding-the-vault.mdx" /* webpackChunkName: "component---src-pages-content-docs-payment-understanding-the-vault-mdx" */),
  "component---src-pages-content-docs-payment-using-the-vault-apis-mdx": () => import("./../../../src/pages/content/docs/payment-using-the-vault-apis.mdx" /* webpackChunkName: "component---src-pages-content-docs-payment-using-the-vault-apis-mdx" */),
  "component---src-pages-content-docs-policy-based-access-control-mdx": () => import("./../../../src/pages/content/docs/policy-based-access-control.mdx" /* webpackChunkName: "component---src-pages-content-docs-policy-based-access-control-mdx" */),
  "component---src-pages-content-docs-privacy-preservation-mdx": () => import("./../../../src/pages/content/docs/privacy-preservation.mdx" /* webpackChunkName: "component---src-pages-content-docs-privacy-preservation-mdx" */),
  "component---src-pages-content-docs-release-notes-2020-mdx": () => import("./../../../src/pages/content/docs/release-notes-2020.mdx" /* webpackChunkName: "component---src-pages-content-docs-release-notes-2020-mdx" */),
  "component---src-pages-content-docs-release-notes-2021-mdx": () => import("./../../../src/pages/content/docs/release-notes-2021.mdx" /* webpackChunkName: "component---src-pages-content-docs-release-notes-2021-mdx" */),
  "component---src-pages-content-docs-troubleshooting-and-known-gaps-mdx": () => import("./../../../src/pages/content/docs/troubleshooting-and-known-gaps.mdx" /* webpackChunkName: "component---src-pages-content-docs-troubleshooting-and-known-gaps-mdx" */),
  "component---src-pages-content-docs-understanding-the-vault-mdx": () => import("./../../../src/pages/content/docs/understanding-the-vault.mdx" /* webpackChunkName: "component---src-pages-content-docs-understanding-the-vault-mdx" */),
  "component---src-pages-content-docs-using-the-vault-apis-mdx": () => import("./../../../src/pages/content/docs/using-the-vault-apis.mdx" /* webpackChunkName: "component---src-pages-content-docs-using-the-vault-apis-mdx" */),
  "component---src-pages-content-docs-what-is-skyflow-mdx": () => import("./../../../src/pages/content/docs/what-is-skyflow.mdx" /* webpackChunkName: "component---src-pages-content-docs-what-is-skyflow-mdx" */),
  "component---src-pages-demos-tsx": () => import("./../../../src/pages/demos.tsx" /* webpackChunkName: "component---src-pages-demos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pre-login-tsx": () => import("./../../../src/pages/pre-login.tsx" /* webpackChunkName: "component---src-pages-pre-login-tsx" */),
  "component---src-pages-sdks-tsx": () => import("./../../../src/pages/sdks.tsx" /* webpackChunkName: "component---src-pages-sdks-tsx" */),
  "component---src-templates-api-template-index-tsx": () => import("./../../../src/templates/ApiTemplate/index.tsx" /* webpackChunkName: "component---src-templates-api-template-index-tsx" */),
  "component---src-templates-docs-template-index-tsx": () => import("./../../../src/templates/DocsTemplate/index.tsx" /* webpackChunkName: "component---src-templates-docs-template-index-tsx" */)
}

