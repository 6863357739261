import React from 'react';
import { Link } from 'gatsby';
import theme, { useGlobalStyles, COLORS } from '../../../utils/theme';
import { handleExternalLinks } from '../../../utils/helper';

export interface StylesDictionary {
  [Key: string]: React.CSSProperties;
}
const useStyles: StylesDictionary = {
  textUnderlineStyle: {
    textDecorationColor: theme.palette.primary.main,
  },
  linkStyle: {
    textDecoration: 'none',
    color: COLORS.LINK_COLOR,
    overflowWrap: 'break-word',
  },
  boxStyle: {
    width: '100%',
  },
};
export interface LinkWrapperProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  isTextDecorationOn?: boolean;
  isLinkFromCard?: boolean;
  maintainPrevPath?: boolean;
}
const checkLink = (href) => {
  return href.endsWith('/') || href.includes('#') ? href : href + '/';
};

const getLinkStyle = (isLinkFromCard) => {
  if (isLinkFromCard) {
    return { ...useStyles.boxStyle, ...useStyles.linkStyle };
  } else {
    return useStyles.linkStyle;
  }
};

const getLocation = () => {
  if (typeof window !== 'undefined') {
    const href = window.location.pathname;
    return href.endsWith('/') ? href : href + '/';
  } else {
    return '/';
  }
};
const LinkWrapper: React.FC<LinkWrapperProps> = (props) => {
  const { href, isLinkFromCard, maintainPrevPath } = props;
  const internal = /^\/(?!\/)/.test(href);
  if (href && href.startsWith('mailto:')) {
    return (
      <a style={useGlobalStyles.DESKTOP_BODY_SMALL_TEXT}>{props.children}</a>
    );
  }
  if (!href || href.startsWith('#')) {
    return <>{props.children}</>;
  }
  if (maintainPrevPath && internal) {
    return (
      <Link
        to={checkLink(href)}
        state={{ prevPath: getLocation() }}
        activeStyle={{ color: 'transparent' }}
        style={
          props.isTextDecorationOn
            ? useStyles.textUnderlineStyle
            : useStyles.linkStyle
        }
      >
        {props.children}
      </Link>
    );
  }
  if (internal) {
    if (href) {
      return (
        <Link
          to={checkLink(href)}
          activeStyle={{ color: 'transparent' }}
          style={
            props.isTextDecorationOn
              ? useStyles.textUnderlineStyle
              : useStyles.linkStyle
          }
        >
          {props.children}
        </Link>
      );
    }
    return <>{props.children}</>;
  } else {
    return (
      <a
        onClick={() => {
          handleExternalLinks(href);
        }}
        data-testid="externalLink"
        style={getLinkStyle(isLinkFromCard)}
      >
        {props.children}
      </a>
    );
  }
};

LinkWrapper.defaultProps = {
  isTextDecorationOn: false,
  isLinkFromCard: false,
};

export default LinkWrapper;
