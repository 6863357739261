import React, { useRef, useState, useEffect } from 'react';
import { Box, styled } from '@mui/system';
import theme, {
  BREAKPOINTS,
  COLORS,
  FONT_LINE_HEIGHT,
} from '../../../utils/theme';
import { LineNo, LineNoPre } from '.';
import Typography from '../../common/Typography';
import PlusIcon from '../../../../static/images/plus.svg';
import MinusIcon from '../../../../static/images/minus.svg';
import {
  calculateCodeBlockLineHeight,
  isMobileView,
} from '../../../utils/helper';

const Pre = styled('pre')({
  margin: theme.spacing(0),
  backgroundColor: theme.palette.grey[100],
  borderBottomRightRadius: 'inherit',
  padding: '16px 27px 16px 16px',
  overflow: 'auto',
  width: '100%',
});

const UL = styled('ul')({
  listStyleType: 'none',
  padding: '0px',
  margin: '0px 0px 0px 26px',
});
let level = 1;
const LI = styled('li')({
  position: 'relative',
  display: 'block',
});

const IMG = styled('img')({
  width: '14px',
  height: '14px',
  position: 'absolute',
  top: '4px',
  left: '-20px',
  cursor: 'pointer',
  userSelect: 'none',
  borderRadius: '2px',
  [BREAKPOINTS.MOBILE]: {
    height: '12px',
    width: '12px',
  },
});

const useStyles = {
  lineNoWrapper: {
    minWidth: theme.spacing(10),
    display: 'inline-grid',
    borderBottomLeftRadius: 'inherit',
  },
  snippetWrapper: {
    display: 'flex',
    borderBottomRightRadius: 'inherit',
    flex: '0 0 100%',
  },
  outerBox: {
    display: 'flex',
  },
  codeStyle: {
    lineHeight: FONT_LINE_HEIGHT.CODE_BLOCK,
  },
  codeWrapper: {
    display: 'flex',
    overflow: 'auto',
    width: '100%',
  },
};

const stringifyStringLiteral = (str: string) => {
  return JSON.stringify(str).slice(1, -1);
};

const decorateWithSpan = (value, className) => {
  return (
    <Typography
      className={className}
      component="span"
      type="DESKTOP_CODE_SECONDARY"
      sx={useStyles.codeStyle}
    >
      {value.toString()}
    </Typography>
  );
};

const jsonToHTML: React.FC<any> = ({ json, maxExpandLevel, dependencies }) => {
  level = 1;
  const inputEl = useRef(null);
  const [count, setCount] = useState(0);
  const punctuationClass = 'hljs-punctuation';
  const stringClass = 'hljs-string';
  const isDesktop = !isMobileView();
  const [open, setOpen] = useState(true);
  React.useEffect(() => {
    setOpen(isDesktop);
  }, [isDesktop]);
  const calculateLineNum = () => {
    setTimeout(() => {
      setCount(0);
      const current: any = inputEl.current;
      if (current) {
        const height = current.clientHeight - 32;
        const length = Math.round(height / calculateCodeBlockLineHeight());
        if (length > 0) {
          setCount(length);
        }
      }
    }, 0);
  };
  const collapseExpand = (target: HTMLElement) => {
    let collapsed;
    if (target.classList.contains('collapser')) {
      collapsed =
        target.parentElement!.getElementsByClassName('collapsible')[0];
      if (collapsed.parentElement.classList.contains('collapsed')) {
        collapsed.parentElement.classList.remove('collapsed');
        target.setAttribute('aria-label', 'collapse');
        target.setAttribute('src', MinusIcon);
      } else {
        collapsed.parentElement.classList.add('collapsed');
        target.setAttribute('aria-label', 'expand');
        target.setAttribute('src', PlusIcon);
      }
    }
    calculateLineNum();
  };
  const clickListener = (event) => {
    collapseExpand(event.target as HTMLElement);
  };
  const buildArrayLI = (json, maxExpandLevel: number, collapsed) => {
    return json.map((value, index) => {
      return (
        <LI key={index}>
          <div className={'hoverable ' + collapsed}>
            {valueToHTML(value, maxExpandLevel)}
          </div>
        </LI>
      );
    });
  };
  const arrayToHTML = (json, maxExpandLevel: number) => {
    const collapsed = level > maxExpandLevel && open ? '' : 'collapsed';
    return (
      <>
        <IMG
          onClick={(event) => clickListener(event)}
          className="collapser"
          aria-label={level > maxExpandLevel + 1 ? 'expand' : 'collapse'}
          src={open ? MinusIcon : PlusIcon}
          data-testid="temp"
        ></IMG>
        {decorateWithSpan('[', punctuationClass)}
        <Typography
          type="DESKTOP_CODE_SECONDARY"
          component="span"
          className="ellipsis"
        ></Typography>
        <UL className="array collapsible">
          {buildArrayLI(json, maxExpandLevel, collapsed)}
        </UL>
        {decorateWithSpan(']', punctuationClass)}
      </>
    );
  };

  const buildObjectLI = (json, maxExpandLevel, collapsed) => {
    const length = Object.keys(json).length;
    return Object.keys(json).map((key, index) => {
      return (
        <LI key={key}>
          <div className={'hoverable ' + collapsed}>
            {decorateWithSpan(key, 'hljs-attr')}
            {decorateWithSpan(':', punctuationClass)}
            {valueToHTML(json[key], maxExpandLevel)}
            {length !== index + 1 && decorateWithSpan(',', punctuationClass)}
          </div>
        </LI>
      );
    });
  };
  const objectToHTML = (json, maxExpandLevel: number) => {
    const collapsed = level > maxExpandLevel && open ? '' : 'collapsed';
    return (
      <>
        <IMG
          onClick={(event) => clickListener(event)}
          className="collapser"
          aria-label={level > maxExpandLevel + 1 ? 'expand' : 'collapse'}
          src={open ? MinusIcon : PlusIcon}
        ></IMG>
        {decorateWithSpan('{', punctuationClass)}
        <span className="ellipsis"></span>
        <UL className="obj collapsible">
          {buildObjectLI(json, maxExpandLevel, collapsed)}
        </UL>
        {decorateWithSpan('}', punctuationClass)}
      </>
    );
  };
  function valueToHTML(value, maxExpandLevel: number) {
    const valueType = typeof value;
    const output = '';
    if (value === undefined || value === null) {
      return decorateWithSpan('null', 'hljs-keyword');
    } else if (value && value.constructor === Array) {
      level++;
      return arrayToHTML(value, maxExpandLevel);
    } else if (value && value.constructor === Date) {
      return decorateWithSpan('"' + value.toISOString() + '"', stringClass);
    } else if (valueType === 'object') {
      level++;
      return objectToHTML(value, maxExpandLevel);
    } else if (valueType === 'number') {
      return decorateWithSpan(value, 'hljs-number');
    } else if (valueType === 'string') {
      return decorateWithSpan(
        '"' + stringifyStringLiteral(value) + '"',
        stringClass,
      );
    } else if (valueType === 'boolean') {
      return decorateWithSpan(value, 'hljs-boolean');
    }
    return output;
  }

  useEffect(() => {
    calculateLineNum();
  }, [...dependencies, inputEl.current]);
  return (
    <>
      <Box sx={useStyles.lineNoWrapper}>
        <LineNoPre>
          {[...Array(count)].map((x, index) => 
            <LineNo key={index}>
              <Typography
                type="DESKTOP_CODE_SECONDARY"
                align="right"
                component="span"
                sx={useStyles.codeStyle}
              >
                {index + 1}
              </Typography>
            </LineNo>
          )}
        </LineNoPre>
      </Box>
      <Box sx={useStyles.codeWrapper}>
        <Box sx={useStyles.snippetWrapper}>
          <Pre className="language-json" ref={inputEl}>
            {valueToHTML(json, maxExpandLevel)}
          </Pre>
        </Box>
      </Box>
    </>
  );
};
export default jsonToHTML;
