import * as React from 'react';
import { Box, styled } from '@mui/system';
import Typography from '../Typography';
import theme, {
  BREAKPOINTS,
  MOBILE_SPACING,
  COLORS,
} from '../../../utils/theme';
import { Button, Paper } from '@mui/material';
import { OktaAuth } from '@okta/okta-auth-js';
import LoadingState from '../LoadingState';
import Info from '../../../../static/images/info.svg';
import globalMessages from '../../../utils/messages/en/globalMessages';
import LinkWrapper from '../Link';
import routes from '../../../docs-config/src';
import { navigate } from 'gatsby';
import ExternalLinkSvg from '../../../../static/images/openStudio.svg';

const useStyles = {
  outerBox: {
    display: 'flex',
    flex: '1 1 0%',
    width: '100%',
  },
  innerBox: {
    display: 'flex',
  },
  centerBoxWithRightNav: {
    width: '699px',
    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
    [BREAKPOINTS.DESKTOP]: {
      width: 'calc(100% - 410px)',
      paddingLeft: theme.spacing(10),
    },
  },
  paperStyle: {
    backgroundColor: COLORS.PRIMARY_AIR,
    display: 'flex',
    marginBottom: '16px',
    padding: theme.spacing(3),
    maxWidth: '521px',
    alignItems:'center',
    [BREAKPOINTS.MOBILE]: {
      maxWidth: 'calc(100% - 20px)',
    },
  },
  icon: {
    color: theme.palette.secondary.main,
    height: theme.spacing(6),
    width: theme.spacing(6),
    paddingRight: '8px',
  },
  centerBoxWithNoRightNav: {
    width: '699px',
    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
    [BREAKPOINTS.DESKTOP]: {
      maxWidth: '410px',
      margin: 'auto',
    },
  },
  imgStyle: {
    padding: theme.spacing(3),
  },
  textStyle: {
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(0),
    maxWidth: 'calc(100% - 124px)',
  },
  linkStyle: {
    textDecoration: 'none',
    color: COLORS.LINK_COLOR,
    overflowWrap: 'break-word',
  },
  openStudioStyle: {
    backgroundColor: COLORS.BACKGROUND_TERTIARY,
    borderRadius: '100px',
    paddingLeft: '20px',
    minWidth: '170px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: '0px 18px',
    alignItems: 'center',
    minHeight: '34px',
    maxHeight: '34px',
    ':hover': {
      backgroundColor: COLORS.BACKGROUND_TERTIARY,
    },
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  mobileOpenStudioStyle: {
    minWidth: '170px',
    backgroundColor: COLORS.BACKGROUND_TERTIARY,
    borderRadius: '100px',
    paddingLeft: '20px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: '0px 21px',
    alignItems: 'center',
    minHeight: '37px',
    maxHeight: '37px',
    width: '106px',
    ':hover': {
      backgroundColor: COLORS.BACKGROUND_TERTIARY,
    },
    marginBottom: '29px',
    marginLeft: '21px',
    [BREAKPOINTS.DESKTOP]: {
      display: 'none',
    },
  },
};

const InnerBox = styled('div')(({ theme }) => ({
  maxWidth: '989px',
  margin: '44px auto',
  marginBottom: '0px',
  [BREAKPOINTS.MOBILE]: {
    maxWidth: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
    margin: 'auto auto',
    marginTop: theme.spacing(3),
    padding: '20px',
  },
  [BREAKPOINTS.DESKTOP]: {
    width: '100%',
  },
}));

const config = {
  issuer: process.env.GATSBY_OKTA_ISSUER_URL,
};
const signIn =
  typeof window !== 'undefined' &&
  process.env.GATSBY_OKTA_ISSUER_URL &&
  new OktaAuth(config);
let oktaClient: OktaAuth;
if (signIn) {
  oktaClient = new OktaAuth(config);
}
const initialSession = async () => {
  if (oktaClient) {
    return oktaClient.session
      .exists()
      .then(async (exists: any) => {
        if (exists) {
          return true;
        } else {
          return false;
        }
      })
      .catch((err: any) => {
        return false;
      });
  }
  return false;
};
interface Props {
  children?: JSX.Element;
  isHeader?: boolean;
  isPreLogin?: boolean;
  isDefaultLayout?: boolean;
  isPrivateRoute?: boolean;
  title?: string;
  isMobileOpenStudio?: boolean;
}
const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
function getcookie(cookiename) {
  if (typeof document !== 'undefined') {
    const cookiestring = document.cookie;
    const cookiearray = cookiestring.split(';');
    for (const element of cookiearray) {
      if (element.trim().match('^' + cookiename + '=')) {
        const href = element.replace(`${cookiename}=`, '').trim();
        if (href && isValidUrl(href)) {
          const domain = new URL(href).host;
          const result = domain.split('.');
          if (
            result.length >= 3 && result[1] === globalMessages.domainName ||
            result[1] === globalMessages.sandoxDomain
          ) {
            return href;
          }
        }
      }
    }
  }
  return routes.preLogin;
}
const SecurityWrapper: React.FC<Props> = ({
  children,
  isHeader,
  isPrivateRoute,
  title,
  isDefaultLayout,
  isPreLogin,
  isMobileOpenStudio,
}) => {
  const [isSessionActive, setIsSessionActive] = React.useState<any>(null);
  const [isloading, setIsLoading] = React.useState(true);
  const getSession = async () => {
    if (oktaClient) {
      return oktaClient.session
        .exists()
        .then(async (exists: any) => {
          if (exists) {
            if (isSessionActive !== true) {
              setIsSessionActive(true);
            }
            return true;
          } else {
            if (isSessionActive !== false) {
              setIsSessionActive(false);
            }
            return false;
          }
        })
        .catch((err: any) => {
          return false;
        });
    }
    return false;
  };
  const getPrivatePage = (title) => {
    return (
      <>
        {
          <Box sx={useStyles.outerBox}>
            <InnerBox>
              <Box sx={useStyles.centerBoxWithRightNav}>
                <Typography variant="h1" sx={{ marginBottom: '12px' }}>
                  {title}
                </Typography>
                <Paper sx={useStyles.paperStyle} elevation={0}>
                  <img
                    src={Info}
                    width="20px"
                    height="20px"
                    style={useStyles.imgStyle}
                    alt="info"
                  />
                  <Typography
                    type="SMALL_SUB_HEADER"
                    sx={useStyles.textStyle}
                    component="span"
                  >
                    {globalMessages.signInNotice}
                  </Typography>
                  <LinkWrapper href={routes.preLogin} maintainPrevPath={true}>
                    <Button variant="contained" sx={{ borderRadius: 4 }}>
                      {globalMessages.signIn}
                    </Button>
                  </LinkWrapper>
                </Paper>
              </Box>
            </InnerBox>
          </Box>
        }
      </>
    );
  };
  const diplayBasedOnsession = (isSessionActive) => {
    return isSessionActive === true ? <>{children}</> : getPrivatePage(title);
  };
  const displayPreLoginBasedOnSession = (isSessionActive) => {
    return isSessionActive ? navigate(routes.home) : <>{children}</>;
  };
  const showOpenStudio = (isloading, isSessionActive) => {
    return (
      <LinkWrapper
        href={
          isSessionActive === false
            ? routes.preLogin
            : getcookie(globalMessages.applicationUrl)
        }
        maintainPrevPath={true}
      >
        <Button
          sx={
            isMobileOpenStudio
              ? useStyles.mobileOpenStudioStyle
              : useStyles.openStudioStyle
          }
          disableRipple
        >
          <img src={ExternalLinkSvg} style={useStyles.icon} alt="arrow_right" />
          <Typography type="SMALL_SUB_HEADER">
            {globalMessages.openStudio}
          </Typography>
        </Button>
      </LinkWrapper>
    );
  };
  React.useEffect(() => {
    const checkSession = async () => {
      const intialValue = await initialSession();
      setIsSessionActive(intialValue);
      setIsLoading(false);
    };
    checkSession();
  }, []);

  function listenCookieChange(callback, interval = 5000) {
    if (typeof document !== 'undefined') {
      let lastCookie = document.cookie;
      setInterval(() => {
        const cookie = document.cookie;
        if (cookie !== lastCookie) {
          try {
            callback({ oldValue: lastCookie, newValue: cookie });
          } finally {
            lastCookie = cookie;
          }
        }
      }, interval);
    }
  }
  listenCookieChange(({ oldValue, newValue }) => {
    const latestCookies = newValue.split(';');
    const oldCookies = oldValue.split(';');
    let previousAppURL = '';
    let latestAppURL = '';
    for (const element of latestCookies) {
      if (element.trim().match('^' + globalMessages.applicationUrl + '=')) {
        latestAppURL = element
          .replace(`${globalMessages.applicationUrl}=`, '')
          .trim();
      }
    }
    for (const element of oldCookies) {
      if (element.trim().match('^' + globalMessages.applicationUrl + '=')) {
        previousAppURL = element
          .replace(`${globalMessages.applicationUrl}=`, '')
          .trim();
      }
    }
    if (previousAppURL !== latestAppURL) {
      getSession();
    }
  }, 5000);

  return (
    <>
      {isDefaultLayout && isPrivateRoute === false && <>{children}</>}
      {isDefaultLayout &&
        isPrivateRoute === true &&
        (isloading === true ? 
          <LoadingState />
         : 
          diplayBasedOnsession(isSessionActive)
        )}
      {isPreLogin &&
        (isloading === true ? 
          <LoadingState />
         : 
          displayPreLoginBasedOnSession(isSessionActive)
        )}
      {isHeader && showOpenStudio(isloading, isSessionActive)}
    </>
  );
};
export default React.memo(SecurityWrapper);
