import HOME from '../../static/images/home.svg';
import GETTING_STARTED from '../../static/images/getting_started.svg';
import VAULT_GOVERNANCE from '../../static/images/vault_governance.svg';
import VAULT_SETTINGS from '../../static/images/vault_settings.svg';
import CUSTOMER_IDENTITY_VAULT from '../../static/images/customer_identity_vault.svg';
import PAYMENT_VAULT from '../../static/images/payment_vault.svg';
import API_REFERENCE from '../../static/images/api_reference.svg';
import RELEASE_NOTES from '../../static/images/release_notes.svg';
import ROUTES from '../docs-config/src';

const sideNavItems = [
  {
    label: 'Home',
    icon: HOME,
    route: '/',
  },
  {
    label: 'Fundamentals',
    icon: GETTING_STARTED,
    child: [
      {
        label: 'What is Skyflow?',
        route: ROUTES.productOverview,
      },
      {
        label: 'Get started with Skyflow',
        route: ROUTES.productGetStarted,
      },
      {
        label: 'Authenticate',
        route: ROUTES.apiAuthentication,
      },
      {
        label: 'Setup Postman',
        route: ROUTES.postmanCollectionSetup,
      },
      {
        label: 'Accounts and environments',
        route: ROUTES.accountsAndEnvironments,
      }
    ],
  },
  {
    label: 'Tokenization',
    icon: VAULT_SETTINGS,
    child: [
      {
        label: 'Overview',
        route: ROUTES.tokenizationOverview,
        private:true
      },
      {
        label: 'APIs',
        route: ROUTES.tokenizationApiQuickstart,
        private:true
      },
      {
        label: 'Compliance',
        route: ROUTES.tokenizationAndCompliance,
        private:true
      },
    ],
  },
  {
    label: 'Data governance',
    icon: VAULT_GOVERNANCE,
    child: [
      {
        label: 'Overview',
        route: ROUTES.dataGovernanceOverview,
        private:true
      },
      {
        label: 'Setup',
        route: ROUTES.dataGovernanceQuickstart,
        private:true
      },
      {
        label: 'Policy enforcement decision logic',
        route: ROUTES.policyEnforcementDecisionLogic,
        private:true
      },
      {
        label: 'Policy expression language reference',
        route: ROUTES.policyExpressionLanguage,
        private:true
      },
      {
        label: 'Context-aware authorization',
        route: ROUTES.contextAwareAuthorizationOverview,
        private:true
      },
    ],
  },
  {
    label: 'Connections',
    icon: CUSTOMER_IDENTITY_VAULT,
    child: [
      {
        label: 'Overview',
        route: ROUTES.connectionsOverview,
        private:true
      },
      {
        label: 'Tips and best practices',
        route: ROUTES.connectionTipsAndBestPractices,
        private:true
      },
      {
        label: 'Guides',
        child: [
          {
            label: 'Plaid',
            route: ROUTES.connectionsPlaid,
            private:true
          },
          {
            label: 'Stripe',
            route: ROUTES.connectionsQuickstart,
            private:true
          },
          {
            label: 'VISA DPS',
            route: ROUTES.connectionsExample,
            private:true
          },
          {
            label: 'Mulesoft',
            route: ROUTES.mulesoftQuickstartGuide,
            private:true
          },
        ],
      },
    ],
  },
  {
    label: 'Network tokenization',
    icon: VAULT_SETTINGS,
    child: [
      {
        label: 'Overview',
        route: ROUTES.networkTokenizationOverview,
        private:true
      },
      {
        label: 'Get started with network tokenization',
        route: ROUTES.networkTokenizationGuide,
        private:true
      },
    ],
  },
  {
    label: 'Advanced guides',
    icon: PAYMENT_VAULT,
    child: [
      {
        label: 'Vault settings',
        route: ROUTES.vaultSettings,
        private:true
      },
      {
        label: 'Key management',
        route: ROUTES.keyManagement,
        private:true
      },
      {
        label: 'Upload files',
        route: ROUTES.fileUpload,
        private:true
      },
      {
        label: 'PCI compliance walkthrough',
        route: ROUTES.pciCompliance,
        private:true
      },
    ],
  },
  {
    label: 'API references',
    icon: API_REFERENCE,
    route: ROUTES.apiReference.home,
    private: false
  },
  {
    label: 'Security best practices checklist',
    icon: API_REFERENCE,
    route: ROUTES.securityBestPracties,
    private:true
  },
  {
    label: 'SDKs',
    icon: API_REFERENCE,
    child: [
      {
        label: 'Handling sensitive data client-side',
        route: ROUTES.handlingDataClientSide,
        private:true,
      },
      {
        label: 'SDKs',
        route: ROUTES.sdks,
        private:true,
      },
    ],
  },
  {
    label: 'Demos and sample apps',
    icon: API_REFERENCE,
    route: ROUTES.demos,
    private:true
  },
];
export default sideNavItems;
