import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import searchIcon from '../../../../static/images/search.svg';
import theme, { COLORS, BREAKPOINTS } from '../../../utils/theme';
import {
  Box,
  InputBase,
  styled,
  alpha,
  Popper,
  Fade,
  Paper,
  ClickAwayListener,
  IconButton,
} from '@mui/material';
import Typography from '../../common/Typography';
import LinkWrapper from '../Link';
import Breadcrumb from '../Breadcrumb';
import parse from 'html-react-parser';
import { useLunr } from 'react-lunr';
import backIcon from '../../../../static/images/arrow-left.svg';
import { isMobile } from 'react-device-detect';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import debounce from 'lodash.debounce';

const useStyles = {
  menuListStyle: {
    '& .MuiList-root.MuiMenu-list': { padding: '2px 4px' },
    '& .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper': {
      borderRadius: '0px 0px 4px 4px',
      marginLeft: '4px',
      boxShadow: theme.shadows[12],
    },
  },
  arrowBackIconStyling: {
    width: '20px',
    height: '20px',
  },
  arrowBack: {
    padding: '0px',
  },
  menuItems: {
    padding: '6px 8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    ':hover': {
      backgroundColor: COLORS.SECONDARY_DUSK,
      '& .MuiTypography-root': {
        color: COLORS.BACKGROUND_PRIMARY,
      },
    },
    borderRadius: '4px',
  },
  listItemWrapper: {
    padding: '12px 16px 16px',
  },
  searchBoxStyle: {
    padding: '0px 16px 0px',
    boxShadow: '0px 0px 16px 0px rgb(0 0 0 / 6%)',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    [BREAKPOINTS.MOBILE]: {
      boxShadow: 'none',
      padding: '0px',
      height: 'unset',
    },
  },
  breadCrumbStyle: {
    marginTop: '4px',
  },
  titleStyle: {
    color: COLORS.TEXT_HIGH_EMPHASIS,
  },
  searchFocusStyle: {
    border: '1px solid',
    borderColor: COLORS.SECONDARY_DUSK,
  },
  searchBoxDefault: {
    boxShadow: 'none',
    padding: '0px 16px 0px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    [BREAKPOINTS.MOBILE]: {
      padding: '0px',
      height: '26px',
    },
  },
};
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '100px',
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: '489px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  border: '1px solid' + theme.palette.grey[600],
  [theme.breakpoints.down('lg')]: {
    width: '234px',
  },
  [BREAKPOINTS.MOBILE]: {
    width: 'calc(100% - 8px)',
    height: '26px',
    marginLeft: theme.spacing(2),
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  paddingLeft: '8px',
  height: '19px',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    paddingLeft: '32px',
    height: '19px',
    transition: theme.transitions.create('width'),
    width: '100%',
    '&:focus::-webkit-input-placeholder': {
      opacity: 0,
    },
  },
}));
const StyledPaper = styled(Paper)(({ theme }) => ({
  boxShadow: theme.shadows[14],
  width: 521,
  marginLeft: '-15px',
  marginTop: '2px',
  [theme.breakpoints.down('lg')]: {
    width: '264px',
  },
  [BREAKPOINTS.MOBILE]: {
    boxShadow: 'none',
    width: '100%',
    marginLeft: '0px',
  },
}));
const DescriptionTypography = styled(Typography)(() => ({
  color: COLORS.TEXT_BODY,
  [BREAKPOINTS.MOBILE]: {
    overflow: 'auto',
    textOverflow: 'unset',
    whiteSpace: 'unset',
  },
}));
interface SearchItem {
  id: string;
  path: string;
  title: string;
  desc: string;
  links: any;
}
const getResults = (searchResults, results, limit) => {
  const duplicateMap = {};
  results.forEach((item) => {
    duplicateMap[item.path] = item;
  });
  for (let index = 0; index < searchResults.length; index++) {
    const element = searchResults[index];
    if (!duplicateMap[element.path]) {
      results.push(element);
      duplicateMap[element.path] = element;
    }
    if (results.length === limit) {
      break;
    }
  }
  return results;
};

const buildDocsSearchResults = (
  headerResults,
  pageResults,
  apiHeaderResults,
  apiContentResults,
  limit,
  includeAPIResults,
) => {
  let resultList: SearchItem[] = [];
  if (headerResults.length > 0) {
    resultList = getResults(headerResults, resultList, limit);
  }
  if (resultList.length < limit && pageResults.length > 0) {
    resultList = getResults(pageResults, resultList, limit);
  }
  if (includeAPIResults) {
    if (resultList.length < limit && apiHeaderResults.length > 0) {
      resultList = getResults(apiHeaderResults, resultList, limit);
    }
    if (resultList.length < limit && apiContentResults.length > 0) {
      resultList = getResults(apiContentResults, resultList, limit);
    }
  }
  return resultList;
};
const buildApiSearchResults = (
  headerResults,
  pageResults,
  apiHeaderResults,
  apiContentResults,
  limit,
  includeAPIResults,
) => {
  let resultList: SearchItem[] = [];
  if (includeAPIResults) {
    if (apiHeaderResults.length > 0) {
      resultList = getResults(apiHeaderResults, resultList, limit);
    }
    if (resultList.length < limit && apiContentResults.length > 0) {
      resultList = getResults(apiContentResults, resultList, limit);
    }
  }
  if (resultList.length < limit && headerResults.length > 0) {
    resultList = getResults(headerResults, resultList, limit);
  }
  if (resultList.length < limit && pageResults.length > 0) {
    resultList = getResults(pageResults, resultList, limit);
  }
  return resultList;
};
// To be Removed once mobile site is ready
const useAPISearchResults = (oldSiteEnabled) => {
  return !(isMobile && oldSiteEnabled);
};
interface SearchFieldProps {
  pageType: 'docs' | 'api' | 'apireference';
  handleClick?: () => void;
  isSearchOpen?: boolean;
}
const addSearchTermToDataLayer = (value) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'searchTermsSubmission',
      searchTerm: value,
    });
  }
  return '';
};

const SearchField: React.FC<SearchFieldProps> = (props) => {
  const { pageType } = props;
  const data = useStaticQuery(graphql`
    query SearchQuery {
      allLocalSearchPages {
        nodes {
          index
          store
        }
      }
      allLocalSearchHeader {
        nodes {
          headerIndex: index
          headerStore: store
        }
      }
      allLocalSearchApiContent {
        nodes {
          apiContentIndex: index
          apiContentStore: store
        }
      }
      allLocalSearchApiHeader {
        nodes {
          apiHeaderIndex: index
          apiHeaderStore: store
        }
      }
    }
  `);
  const { index, store } = data.allLocalSearchPages.nodes[0];
  const { headerIndex, headerStore } = data.allLocalSearchHeader.nodes[0];
  const { apiContentIndex, apiContentStore } =
    data.allLocalSearchApiContent.nodes[0];
  const { apiHeaderIndex, apiHeaderStore } =
    data.allLocalSearchApiHeader.nodes[0];

  const [query, setQuery] = useState('');
  const [val, setValue] = React.useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isFocused, setFocused] = useState(false);
  const open = Boolean(anchorEl);
  const flags = useFlags(); // To be Removed once mobile site is ready
  const headerResults = useLunr(query, headerIndex, headerStore);
  const pageResults = useLunr(query, index, store);
  const apiHeaderResults = useLunr(query, apiHeaderIndex, apiHeaderStore);
  const apiContentResults = useLunr(query, apiContentIndex, apiContentStore);
  let results: SearchItem[] = [];
  if (pageType === 'api') {
    results = buildApiSearchResults(
      headerResults,
      pageResults,
      apiHeaderResults,
      apiContentResults,
      5,
      useAPISearchResults(flags.enableApiReference),
    );
  } else {
    results = buildDocsSearchResults(
      headerResults,
      pageResults,
      apiHeaderResults,
      apiContentResults,
      5,
      useAPISearchResults(flags.enableApiReference),
    );
  }
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = event.target.value;
    setValue(value);
    setQuery(
      value +
        ' | ' +
        '*' +
        value +
        '*' +
        ' | ' +
        '*' +
        value +
        ' | ' +
        value +
        '*',
    );
    if (event.target.value && results.length > 0) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleKeyup = (event) => {
    setFocused(false);
    if (val && results.length > 0) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleMenuItemClick = () => {
    setAnchorEl(null);
    if (props.handleClick) {
      props.handleClick();
    }
  };
  const handleOnfocus = (event) => {
    setFocused(true);
    if (val && results.length > 0) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const highLight = (content) => {
    if (!content) {
      return '';
    }
    const options = {
      htmlparser2: {
        lowerCaseTags: false,
      },
    };
    const regex = new RegExp(val, 'gi');
    const highlightedContent = content.replace(regex, (str) => `<b>${str}</b>`);
    return parse(highlightedContent, options);
  };
  const updateSearchTerm = (event) => {
    const value = event.target.value;
    if (flags && flags.enableDocAnalyticsEvents === true) {
      addSearchTermToDataLayer(value);
    }
  };
  const handleClickAway = () => {
    if (!isFocused) {
      setAnchorEl(null);
    }
  };
  const debounceOnChange = React.useCallback(
    debounce(updateSearchTerm, 1000),
    [],
  );

  return (
    <Box sx={open ? useStyles.searchBoxStyle : useStyles.searchBoxDefault}>
      {props.isSearchOpen && 
        <IconButton
          disableRipple
          sx={useStyles.arrowBack}
          aria-label="arrow back icon"
          onClick={props.handleClick}
        >
          <img
            src={backIcon}
            style={useStyles.arrowBackIconStyling}
            alt="search back icon"
          />
        </IconButton>
      }
      <Search sx={isFocused ? useStyles.searchFocusStyle : {}}>
        <SearchIconWrapper>
          <img src={searchIcon} alt="search" />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search"
          inputProps={{ 'aria-label': 'search' }}
          value={val}
          onBlur={handleKeyup}
          onChange={(e) => {
            debounceOnChange(e);
            handleChange(e);
          }}
          onFocus={handleOnfocus}
        />
      </Search>
      {results.length > 0 && 
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement={'bottom-start'}
            transition
            style={{ zIndex: '111' }}
            disablePortal={true}
          >
            {({ TransitionProps }) => 
              <Fade {...TransitionProps} timeout={0}>
                <StyledPaper elevation={8}>
                  <Box sx={useStyles.listItemWrapper}>
                    {results
                      .filter((item) => item.title)
                      .map((item, index) => 
                        <Box
                          onClick={() => handleMenuItemClick()}
                          sx={useStyles.menuItems}
                          key={item.path}
                        >
                          <LinkWrapper href={item.path}>
                            <Typography
                              type="DESKTOP_BODY_PARAGRAPH_MEDIUM"
                              sx={useStyles.titleStyle}
                            >
                              {highLight(item.title)}
                            </Typography>
                            <DescriptionTypography
                              type="DESKTOP_BODY_SMALL_TEXT"
                              noWrap
                            >
                              {highLight(item.desc)}
                            </DescriptionTypography>
                            <Box sx={useStyles.breadCrumbStyle}>
                              <Breadcrumb
                                links={item.links}
                                onClick={handleMenuItemClick}
                              />
                            </Box>
                          </LinkWrapper>
                        </Box>
                      )}
                  </Box>
                </StyledPaper>
              </Fade>
            }
          </Popper>
        </ClickAwayListener>
      }
    </Box>
  );
};
export default SearchField;
