import React from 'react';
import LinkWrapper from '../../common/Link';
import { styled, Box } from '@mui/system';
import theme, {
  COLORS,
  BREAKPOINTS,
  FONT_LINE_HEIGHT,
  FONT_SIZES,
  pxToRem,
} from '../../../utils/theme';
import { withTheme } from '@emotion/react';
import Typography from '../../common/Typography';
import Table from '../../common/Table';
import TableRow from '../../common/TableRow';
import TableHeader from '../../common/TableHeader';
import TableData from '../../common/TableData';
import { Link } from 'gatsby';

const releaseParagraphStyle = {
  marginBottom: theme.spacing(5),
} as const;
const paragraphStyle = {
  marginBottom: theme.spacing(4),
} as const;
const StyledLi = {
  display: 'list-item',
  marginBottom: '4px',
} as const;
const styledUL = {
  marginBlockStart: '8px',
  marginBlockEnd: '8px',
};
const styledOL = {
  marginBlockStart: '8px',
  marginBlockEnd: '8px',
};
const useStyles = {
  linkStyle: {
    textDecoration: 'none',
    color: COLORS.LINK_COLOR,
  },
};
const headerAlignment = (headerType) => {
  if (headerType === 'h1') {
    return { marginBottom: '12px' };
  } else if (headerType === 'h2') {
    return {
      marginTop: '40px',
      marginBottom: '10px',
      [BREAKPOINTS.MOBILE]: {
        marginTop: '32px',
      },
    };
  } else if (headerType === 'h3') {
    return { marginTop: '20px', marginBottom: '4px' };
  } else if (headerType === 'h4') {
    return { marginBottom: '8px' };
  } else if (headerType === 'h5') {
    return { marginBottom: '4px' };
  } else if (headerType === 'h6') {
    return { marginBottom: '2px' };
  }
};

const UL = withTheme(styled('ul')`
  list-style: none;
  margin-top: 6px;
  li:before {
    content: '';
    border: 6px ${(props) => props.theme.palette.grey[500]} solid !important;
    border-radius: 100px;
    margin-top: 7px;
    margin-left: -24px;
    position: absolute;
  }
`);

const Li = withTheme(styled('li')`
  font-weight: bold;
  border-left: 2px solid ${(props) => props.theme.palette.grey[200]};
  padding-left: 17px;
  margin-left: -37px;
`);

export const StyledPre = styled('pre')({
  width: '100%',
  margin: '24px 0px',
  fontSize: pxToRem(FONT_SIZES.CODE_SECONDARY),
  lineHeight: `${FONT_LINE_HEIGHT.CODE_SECONDARY}px`,
  [BREAKPOINTS.MOBILE]: {
    fontSize: '12px',
  },
});
const getAutoLink = (props) => {
  if (props.id) {
    return props.id;
  } else if (typeof props === 'string') {
    return props.trim().replace(' ', '-').toLowerCase();
  } else if (props.children && typeof props.children === 'string') {
    return props.children.trim().replace(' ', '-').toLowerCase();
  }
};
const isReleasePage = () => {
  if (
    typeof window !== 'undefined' &&
    window.location.pathname.includes('/release-notes')
  ) {
    return true;
  } else {
    return false;
  }
};

export const getListTag = (props) => {
  if (isReleasePage()) {
    return <Li {...props}></Li>;
  } else {
    return (
      <Typography
        component={'span'}
        type="DESKTOP_BODY_PARAGRAPH"
        sx={StyledLi}
        {...props}
      ></Typography>
    );
  }
};

export const listItem = (props) => {
  return (
    <Typography
        component={'span'}
        type="DESKTOP_BODY_SMALL_TEXT"
        sx={StyledLi}
        {...props}
    ></Typography>
  );
};

export const hardbreak = () => {
  return <br />;
};

export const getDivTag = (props) => {
  return <div {...props}></div>;
};

export const getULTag = (props) => {
  if (isReleasePage()) {
    return <UL {...props}></UL>;
  } else {
    return props.children ? 
      <ul style={styledUL}>{props.children}</ul>
     : 
      <ul style={styledUL}>{props}</ul>
    ;
  }
};

export const getAnchorTag = (props) => {
  if (props.href.startsWith('#')) {
    return (
      <Link
        to={props.href}
        activeStyle={{ color: 'transparent' }}
        style={useStyles.linkStyle}
      >
        {props.children}
      </Link>
    );
  } else {
    return <LinkWrapper href={props.href}>{props.children}</LinkWrapper>;
  }
};

export const getParagraph = (props) => {
  if (isReleasePage()) {
    return (
      <Typography type="DESKTOP_BODY_SMALL_TEXT" sx={releaseParagraphStyle}>
        {props.children}
      </Typography>
    );
  } else {
    return (
      <Typography type="DESKTOP_BODY_PARAGRAPH" sx={paragraphStyle}>
        {props.children}
      </Typography>
    );
  }
};

const getOnlyHeaderWithSVGHidden = (item, headerType, props) => {
  if (typeof item === 'string') {
    return (
      <Typography
        variant={headerType}
        id={getAutoLink(item)}
        sx={headerAlignment(headerType)}
        key={props.id}
      >
        {item}
      </Typography>
    );
  }
};
export const getSectionHeadingTag = (headerType, props) => {
  return (
    <>
      {typeof props.children === 'string' && 
        <Typography
          variant={headerType}
          sx={headerAlignment(headerType)}
          id={props.id}
          className="header-tag"
          key={props.id}
        >
          {props.children}
        </Typography>
      }
      {typeof props.children !== 'string' && props.children.length > 0 && 
        <>
          {props.children &&
            props.children.length > 0 &&
            props.children.map((item, index) => {
              return (
                <Box
                  key={props.id + index + item}
                  className="header-tag"
                  id={props.id}
                >
                  {typeof item === 'string' && 
                    <Typography
                      variant={headerType}
                      sx={headerAlignment(headerType)}
                      id={props.id}
                    >
                      {item}
                    </Typography>
                  }
                  {typeof item !== 'string' &&
                    item.props &&
                    item.props.mdxType === 'a' && <a id={item.props.name}></a>}

                  {typeof item !== 'string' &&
                    item.props &&
                    item.props.children &&
                    getOnlyHeaderWithSVGHidden(
                      item.props.children,
                      headerType,
                      props,
                    )}
                </Box>
              );
            })}
        </>
      }
      {typeof props.children !== 'string' && !props.children.length && 
        <Typography
          variant={headerType}
          id={props.id}
          sx={headerAlignment(headerType)}
          className="header-tag"
          key={props.id}
        >
          {props.children.props.children}
        </Typography>
      }
    </>
  );
};
export const getHeadingTag = (headerType, props) => {
  return (
    <>
      {typeof props.children === 'string' && 
        <Typography
          variant={headerType}
          id={getAutoLink(props)}
          sx={headerAlignment(headerType)}
          key={props.id}
        >
          {props.children}
        </Typography>
      }
      {typeof props.children !== 'string' && props.children.length > 0 && 
        <>
          {props.children &&
            props.children.length > 0 &&
            props.children.map((item, index) => {
              return (
                <Box key={props.id + index}>
                  {typeof item === 'string' && 
                    <Typography
                      variant={headerType}
                      id={getAutoLink(item)}
                      sx={headerAlignment(headerType)}
                    >
                      {item}
                    </Typography>
                  }
                  {typeof item !== 'string' &&
                    item.props &&
                    item.props.mdxType === 'a' && <a id={item.props.name}></a>}

                  {typeof item !== 'string' &&
                    item.props &&
                    item.props.children &&
                    getOnlyHeaderWithSVGHidden(
                      item.props.children,
                      headerType,
                      props,
                    )}
                </Box>
              );
            })}
        </>
      }
      {typeof props.children !== 'string' && !props.children.length && 
        <Typography
          variant={headerType}
          id={props.id}
          sx={headerAlignment(headerType)}
          key={props.id}
        >
          {props.children}
        </Typography>
      }
    </>
  );
};
export const getOLTags = (props) => {
  return props.children ? 
    <ol {...props} style={styledOL}>
      {props.children}
    </ol>
   : 
    <ol style={styledOL}>{props}</ol>
  ;
};
const MDXComponents = {
  h1: (props) => getHeadingTag('h1', props),
  h2: (props) => getSectionHeadingTag('h2', props),
  h3: (props) => getSectionHeadingTag('h3', props),
  h4: (props) => getHeadingTag('h4', props),
  h5: (props) => getHeadingTag('h5', props),
  h6: (props) => getHeadingTag('h6', props),
  p: (props) => getParagraph(props),
  li: (props) => getListTag(props),
  a: (props) => getAnchorTag(props),
  ul: (props) => getULTag(props),
  ol: (props) => getOLTags(props),
  tr: (props) => <TableRow>{props.children}</TableRow>,
  th: (props) => <TableHeader>{props.children}</TableHeader>,
  td: (props) => <TableData>{props.children}</TableData>,
  table: (props) => <Table {...props}>{props.children}</Table>,
};

export default MDXComponents;
