module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/skyflow-docs/skyflow-docs/node_modules/gatsby-remark-copy-linked-files","id":"5f12bfa9-ad02-54ce-915c-564ac5191deb","name":"gatsby-remark-copy-linked-files","version":"5.25.0","modulePath":"/home/runner/work/skyflow-docs/skyflow-docs/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[],"ignoreFileExtensions":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/runner/work/skyflow-docs/skyflow-docs/node_modules/gatsby-remark-autolink-headers","id":"697ca539-ae59-56a1-bbda-5a071af88c8e","name":"gatsby-remark-autolink-headers","version":"5.25.0","modulePath":"/home/runner/work/skyflow-docs/skyflow-docs/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"enableCustomId":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/skyflow-docs/skyflow-docs","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"enableCustomId":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/skyflow-docs/skyflow-docs/src/components/Layout/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Skyflow Documentation","short_name":"Skyflow Docs","start_url":"/","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"991d065ebe7bd601cd689d9a2dea847c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-4TQKCS87N4"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-launchdarkly/gatsby-browser.js'),
      options: {"plugins":[],"clientSideID":"6239bfe86311080c74509bae"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WV6GF8S","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
