import * as React from 'react';
import ExpandLess from '../../../../static/images/expandLess.svg';
import ExpandMore from '../../../../static/images/expandMore.svg';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Collapse,
} from '@mui/material';
import theme, { COLORS } from '../../../utils/theme';
import TagType from './TagType';
import LinkWrapper from '../Link';
import Typography from '../../common/Typography';
import { isMobile } from 'react-device-detect';
import { useNavBar } from './hook';

const useStyles = {
  icon: {
    color: COLORS.TEXT_LOW_EMPHASIS,
    height: '14px',
    width: '14px',
    paddingLeft: '6px',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  active_text: {
    color: COLORS.TEXT_HIGH_EMPHASIS,
    margin: '0px',
  },
  child_active_text: {
    color: COLORS.TEXT_HIGH_EMPHASIS,
    margin: '0px',
  },
  default: {
    color: COLORS.TEXT_HIGH_EMPHASIS,
    margin: '0px',
  },
  child_default: {
    color: COLORS.TEXT_HIGH_EMPHASIS,
    margin: '0px',
  },
  selector_active: {
    borderLeft: ' solid' + theme.palette.primary.main,
    paddingLeft: theme.spacing(4),
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  selector_default: {
    borderLeft: 'solid' + theme.palette.grey[500],
    paddingLeft: theme.spacing(4),
    paddingRight: '10px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  parentContainer: {
    overflow: 'auto',
    paddingTop: '4px',
    paddingBottom: '0px',
  },
  childContainer: {
    overflow: 'auto',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  method: { mr: '6px', mb: '1px', mt: '1px' },
  tag: { mr: '6px' },
  listItemButtonStyle: {
    pl: '10px',
    pr: '10px',
    paddingTop: '14px',
    paddingBottom: '14px',
    '&:hover': {
      backgroundColor: COLORS.BACKGROUND_SECONDARY,
      borderRadius: '4px',
    },
  },
  parentListItemText: {
    margin: 'auto',
  },
  childListItemText: {
    paddingTop: '6px',
    paddingBottom: '6px',
    margin: 'auto',
  },
  childListItemTextLevel2WithChild: {
    paddingTop: '6px',
    paddingBottom: '8px',
    margin: 'auto',
  },
  childListItemTextLevel3: {
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: 'auto',
  },
  styledBorder: {
    borderLeft: 'solid' + theme.palette.grey[500],
  },
  selectedListItemButton: {
    pl: '10px',
    pr: '10px',
    paddingTop: '14px',
    paddingBottom: '12px',
    borderRadius: '4px',
  },
  selectedListItemButtonWithNoChilds: {
    pl: '10px',
    pr: '10px',
    paddingTop: '14px',
    paddingBottom: '14px',
    borderRadius: '4px',
    backgroundColor: COLORS.BACKGROUND_SECONDARY,
    '&:hover': {
      backgroundColor: COLORS.BACKGROUND_SECONDARY,
    },
  },
  versionStyle: {
    margin: 'auto',
    width: 'max-content',
    padding: '20px 0px',
  },
  VersionName: {
    color: theme.palette.grey[700],
  },
  dividerStyle: {
    borderTop: '1px solid' + theme.palette.grey[300],
    marginTop: '20px',
  },
  childBox: {
    paddingBottom: '14px',
  },
  boxAtLevelTwoWithChild: {
    marginLeft: '8px',
  },
  itemIcon: {
    height: '16px',
    paddingTop: '1px',
    paddingBottom: '1px',
    display: 'none',
  },
  iconStyles: {
    width: '16px',
    height: '16px',
  },
  itemTextStyle: {
    margin: 'auto',
  },
  apiChilds: {
    padding: '0px',
    margin: 'auto',
  },
  apichildItemText: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
};

const textSelector = (level, index, open) => {
  if (level >= 2) {
    return open[index] ? useStyles.child_active_text : useStyles.child_default;
  } else {
    return open[index] ? useStyles.active_text : useStyles.default;
  }
};
const apiTextSelector = (level, index, route, activeItem, open) => {
  if (level >= 2 && activeItem && route === '#' + activeItem) {
    return useStyles.child_active_text;
  } else if (level >= 2 && !activeItem && route !== '#' + activeItem) {
    return useStyles.child_default;
  } else {
    return open[index] ? useStyles.active_text : useStyles.default;
  }
};
const checkForTextSelector = (level, index, route, activeItem, open) => {
  return route && level >= 2
    ? apiTextSelector(level, index, route, activeItem, open)
    : textSelector(level, index, open);
};
const selectorBarStyling = (level, open, index, child) => {
  if (level === 1) {
    return { ml: '8px', mr: '8px' };
  } else if (level >= 2) {
    return {
      pl: '17px',
      backgroundColor:
        open[index] && !child ? COLORS.BACKGROUND_SECONDARY : 'inherit',
      '&:hover': {
        backgroundColor: !open[index] ? COLORS.BACKGROUND_SECONDARY : 'inherit',
      },
    };
  } else {
    return { pl: 6 };
  }
};
const apiChildItemsStyling = (level, activeItem, route) => {
  if (level >= 2) {
    return {
      backgroundColor:
        route && route === '#' + activeItem
          ? COLORS.BACKGROUND_SECONDARY
          : 'inherit',
      '&:hover': {
        backgroundColor: COLORS.BACKGROUND_SECONDARY,
      },
    };
  }
};
const applyStyleToLevelTwo = (level) => {
  return level === 2 ? useStyles.boxAtLevelTwoWithChild : useStyles.childBox;
};
const selectContainer = (level) => {
  if (level === 1) {
    return useStyles.parentContainer;
  } else {
    return useStyles.childContainer;
  }
};
const childListButtonStyle = (level, type, child, index, open) => {
  if (level === 1) {
    return useStyles.parentListItemText;
  } else if (level !== 1 && type === 'api') {
    return useStyles.apiChilds;
  } else if (level === 2) {
    return child && open[index]
      ? useStyles.childListItemTextLevel2WithChild
      : useStyles.childListItemText;
  } else {
    return level > 2
      ? useStyles.childListItemTextLevel3
      : useStyles.childListItemText;
  }
};
const getChilds = (index, open) => {
  return (
    <img
      src={open[index] ? ExpandLess : ExpandMore}
      style={useStyles.icon}
      alt={open[index] ? 'expand_less' : 'expand_more'}
    />
  );
};

const selector = (level, index, child, method, open) => {
  if (level >= 2 && !method) {
    return open[index] && !child
      ? useStyles.selector_active
      : useStyles.selector_default;
  } else if (level >= 2 && method) {
    return useStyles.apichildItemText;
  } else {
    if (open[index]) {
      return child
        ? useStyles.selectedListItemButton
        : useStyles.selectedListItemButtonWithNoChilds;
    } else {
      return useStyles.listItemButtonStyle;
    }
  }
};
const getStyledBorder = (level) => {
  return level >= 2 ? useStyles.styledBorder : {};
};
const getTypography = (level, index, route, activeItem, open, pageType) => {
  if (level === 1) {
    return open[index]
      ? 'DESKTOP_ACTION_PRIMARY_BOLD'
      : 'DESKTOP_ACTION_PRIMARY';
  } else if (level >= 2 && route && pageType === 'api') {
    return route === '#' + activeItem
      ? 'DESKTOP_ACTION_SECONDARY_MEDIUM'
      : 'DESKTOP_ACTION_SECONDARY';
  } else {
    return open[index]
      ? 'DESKTOP_ACTION_SECONDARY_MEDIUM'
      : 'DESKTOP_ACTION_SECONDARY';
  }
};
const GetChildsAboveLevel3 = (value, index, handleClick, open, setDrawer) => {
  return (
    <LinkWrapper href={value.route}>
      <ListItemButton
        disableRipple={true}
        onClick={() =>
          handleClick(index, value.child, value.route, setDrawer, value.label)
        }
        sx={
          open[index] ? useStyles.selector_active : useStyles.selector_default
        }
      >
        <ListItemText sx={useStyles.itemTextStyle}>
          <Typography
            type="DESKTOP_ACTION_PRIMARY"
            sx={
              open[index]
                ? useStyles.child_active_text
                : useStyles.child_default
            }
          >
            {value.label}
          </Typography>
        </ListItemText>
        {value.child && getChilds(index, open)}
      </ListItemButton>
    </LinkWrapper>
  );
};

export enum pageType {
  docs,
  api,
}
const apiPage = pageType.api;
const getPaddingAfterlevel2WithChild = (level, type) => {
  if (type !== pageType[apiPage]) {
    return <div style={{ paddingBottom: level === 2 ? '8px' : '0' }}></div>;
  }
};
const NavBar: React.FC<any> = ({
  sideNavItems,
  level,
  type,
  pathName,
  setDrawer,
  activeItem,
  setActiveItem,
  parentActiveItem,
  setParentActiveItem,
}) => {
  const { handleClick, open } = useNavBar(
    sideNavItems,
    type,
    pathName,
    level,
    activeItem,
    setActiveItem,
    parentActiveItem,
    setParentActiveItem,
  );
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      sx={selectContainer(level)}
    >
      {sideNavItems.map((value, index) => 
        <List
          component="div"
          disablePadding
          key={index}
          sx={
            value.method
              ? apiChildItemsStyling(level, activeItem, value.route)
              : selectorBarStyling(level, open, index, value.child)
          }
        >
          {level > 3 &&
            GetChildsAboveLevel3(value, index, handleClick, open, setDrawer)}

          {level <= 3 && value.label && 
            <LinkWrapper href={value.route}>
              <ListItemButton
                disableRipple={true}
                onClick={() =>
                  handleClick(
                    index,
                    value.child,
                    value.route,
                    type,
                    setDrawer,
                    value.label,
                  )
                }
                sx={selector(level, index, value.child, value.method, open)}
              >
                {value.icon && 
                  <ListItemIcon sx={useStyles.itemIcon}>
                    <img
                      src={value.icon}
                      style={useStyles.iconStyles}
                      alt="skyflow"
                    />
                  </ListItemIcon>
                }
                {value.method && 
                  <ListItemIcon sx={useStyles.method}>
                    <TagType method={value.method} />
                  </ListItemIcon>
                }
                <ListItemText
                  sx={childListButtonStyle(
                    level,
                    type,
                    value.child,
                    index,
                    open,
                  )}
                >
                  <Typography
                    type={getTypography(
                      level,
                      index,
                      value.route,
                      activeItem,
                      open,
                      type,
                    )}
                    sx={checkForTextSelector(
                      level,
                      index,
                      value.route,
                      activeItem,
                      open,
                    )}
                    data-testid={value.label + '_' + index}
                  >
                    {value.label}
                  </Typography>
                </ListItemText>
                {value.tags &&
                  value.tags.map((tag) => {
                    return (
                      <ListItemIcon sx={useStyles.tag}>
                        <TagType method={tag} />
                      </ListItemIcon>
                    );
                  })}
                {value.child && getChilds(index, open)}
              </ListItemButton>
            </LinkWrapper>
          }
          {value.label ? 
            <Collapse
              in={open[index]}
              timeout="auto"
              unmountOnExit
              sx={getStyledBorder(level)}
            >
              {value.child && open[index] && 
                <>
                  <div style={applyStyleToLevelTwo(level)}>
                    <NavBar
                      sideNavItems={value.child}
                      level={level + 1}
                      type={type}
                      pathName={pathName}
                      setDrawer={setDrawer}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                      parentActiveItem={parentActiveItem}
                      setParentActiveItem={setParentActiveItem}
                    />
                  </div>
                  {getPaddingAfterlevel2WithChild(level, type)}
                </>
              }
            </Collapse>
           : 
            <>
              {value.child && 
                <>
                  <div style={applyStyleToLevelTwo(level)}>
                    <NavBar
                      sideNavItems={value.child}
                      level={level + 1}
                      type={type}
                      pathName={pathName}
                      setDrawer={setDrawer}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                      parentActiveItem={parentActiveItem}
                      setParentActiveItem={setParentActiveItem}
                    />
                  </div>
                  {getPaddingAfterlevel2WithChild(level, type)}
                </>
              }
            </>
          }
        </List>
      )}
      {level === 1 && process.env.GATSBY_VER_NO && 
        <Box sx={useStyles.dividerStyle}>
          <Box sx={useStyles.versionStyle}>
            <Typography
              type="DESKTOP_ACTION_SECONDARY"
              sx={useStyles.VersionName}
            >
              {process.env.GATSBY_VER_NO}
            </Typography>
          </Box>
        </Box>
      }
    </List>
  );
};
export default React.memo(NavBar);
