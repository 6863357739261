const globalMessages = {
  documentation: 'Documentation',
  apiReference: 'API Reference',
  signIn: 'Sign In',
  copyRight: '© 2022 Skyflow, Inc. All rights reserved.',
  termsAndCondition: 'Terms and Conditions',
  cookiePolicy: 'Cookie Policy',
  skyFlowSite: 'Skyflow.com',
  docsPage: 'DOCS',
  apiReferencePage: 'API',
  start: 'START',
  rightNavHeader: 'In this article',
  homePage: 'home',
  sdKPage: 'SDKs',
  demoAndSampleAppPage: 'Demos and Sample Apps',
  apiReferencePages: 'API Reference',
  signInNotice: 'This page is only available to signed-in Skyflow users.',
  Trial: 'Trial',
  Production: 'Production',
  privacyPolicy: 'Privacy Policy',
  openStudio: 'Open Studio',
  applicationUrl: 'application-url',
  domainName: 'skyflow',
  Sandbox: 'Sandbox',
  sandoxDomain:'skyflow-preview',
  applicationURL:'application-url'
};

export default globalMessages;
